import React, { useEffect } from 'react'
import {motion} from "framer-motion"
import '../components/Styles/home.css'
import '../components/Styles/service.css'
import xm1 from '../assets/images/aboutmain.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Slide from './Slide'


const Service = () => {
  useEffect(()=>{
    AOS.init();
   
  })

  return (
    <div>
        
        <section className='main_sec' >
        <div className='x_row1'>
          <div className='x_col'>
          <br /><br /><br />
            <h1 data-aos="fade-right">Integrated<br /> Business<br /> Services 
</h1>
<p data-aos="fade-right">Welcome to Xceed Office's Integrated Business Services! We provide a comprehensive suite of services to support your business's growth and efficiency. Our team of experts is dedicated to delivering exceptional solutions tailored to your unique needs. </p>

          </div>
          <div className='x_col'>
            <img src={xm1} alt="" />
          </div>
        </div>
        </section>
<br /><br /><br /><br />
<section className='mid_service'>
    <p  data-aos="zoom-in">We Specialize In:
</p>
    <h3 data-aos="zoom-in">Accounting and Financial Services</h3>
    <br /><br />
    <div className='service_row' data-aos="zoom-in">
        <div className='ser_col'>
            <h3>Accounting Services</h3>
            <p>Our experienced accountants ensure your financial records are accurate and up to date.</p>
<br />
            <h4>Read More</h4>
        </div>
        <div className='ser_col'>
            <h3>Financial
Consultancy</h3>
<p>Make informed decisions with our expert financial advice and insights.</p>
<br />
            <h4>Read More</h4>
        </div>
        <div className='ser_col'>
            <h3>Business Management

</h3>
<p>Streamline your operations and drive growth with our strategic business management solutions.</p>
<br />
            <h4>Read More</h4>
        </div>
    </div>
    <br /><br />
    <div className='service_row' data-aos="zoom-in">
        <div className='ser_col'>
            <h3>Company Management</h3>
            <p>We provide comprehensive company management services to keep your business running smoothly.

</p>
<br />
            <h4>Read More</h4>
        </div>
        <div className='ser_col'>
        <h3>Financial & Insurance Jobs</h3>
            <p>Explore opportunities and find the best financial and insurance roles with our dedicated job search.</p>
<br />
            <h4>Read More</h4>
        </div>
        <div className='ser_col' style={{visibility:"hidden"}}>
        <h3>Financial
Consultancy</h3>
<br />
            <h4>Read More</h4>
        </div>
    </div>
 </section>
 <br /><br /><br /><br />
<section className='mid_service'>
    <p data-aos="zoom-in"> We Are Expertise In</p>
    <h3 data-aos="zoom-in">Marketing Services</h3>
    <br /><br />
    <div className='service_row' data-aos="zoom-in">
        <div className='ser_colb'>
            <h3>Digital Marketing</h3>
            <p>Boost your online presence and reach your target audience with our digital marketing strategies.</p>
<br />
            <h4>Read More</h4>
        </div>
        <div className='ser_colb'>
            <h3>Content Creation</h3>
            <p>Engage your audience with compelling content crafted by our creative team.

</p>
<br />
            <h4>Read More</h4>
        </div>
        <div className='ser_colb'>
            <h3>Search Engine Optimization</h3>
            <p>Increase your website's visibility and drive traffic with our SEO services.
</p>
<br />
            <h4>Read More</h4>
        </div>
    </div>
    <br /><br />
    <div className='service_row' data-aos="zoom-in">
        <div className='ser_colb'>
            <h3>Social Media Marketing</h3>
            <p>Connect with your customers and build your brand on social media.</p>
<br />
            <h4>Read More</h4>
        </div>
        <div className='ser_colb'>
            <h3>Custom Website Design</h3>
            <p>Stand out online with a unique and user-friendly website designed by our experts.</p>
<br />
            <h4>Read More</h4>
        </div>
        <div className='ser_colb' style={{visibility:"hidden"}}>
            <h3>Financial
Consultancy</h3>
<br />
            <h4>Read More</h4>
        </div>
    </div>
 </section>
<br /><br /><br /><br />
<section className='mid_service'>
    <p data-aos="zoom-in">We Are Expertise In</p>
    <h3 data-aos="zoom-in">Administrative Support</h3>
    <br /><br />
    <div className='service_row' data-aos="zoom-in">
        <div className='ser_col'>
            <h3>Data Entry</h3>
            <p>Save time and ensure accuracy with our reliable data entry services.

</p>
<br />
            <h4>Read More</h4>
        </div>
        <div className='ser_col'>
            <h3>Email Management</h3>
            <p>Keep your inbox organized and never miss an important email again.

</p>
<br />
            <h4>Read More</h4>
        </div>
        <div className='ser_col'>
            <h3>Calendar Management</h3>
            <p>Stay on top of your schedule with our efficient calendar management services.

</p>
<br />
            <h4>Read More</h4>
        </div>
    </div>
    <br /><br />
    <div className='service_row' data-aos="zoom-in">
        <div className='ser_col'>
            <h3 >Filing and Records Management</h3>
            <p>Ensure your documents are secure and easily accessible with our filing and records management solutions.

</p>
<br />
            <h4>Read More</h4>
        </div>
        <div className='ser_col'>
            <h3>Phone Answering and Reception</h3>
            <p>Make a great first impression with our professional phone answering and reception services.</p>
<br />
            <h4>Read More</h4>
        </div>
        <div style={{visibility:"hidden"}} className='ser_col'>
            <h3>Financial
Consultancy</h3>
<br />
            <h4>Read More</h4>
        </div>
    </div>
 </section>
 <br /><br /><br /><br />
<section className='mid_service'>
    <p data-aos="zoom-in">We Are Expertise In</p>
    <h3 data-aos="zoom-in">Legal Services
</h3>
    <br /><br />
    <div className='service_row' data-aos="zoom-in">
        <div className='ser_colb'>
            <h3>Company Registrations</h3>
            <p>Start your business on the right foot with our hassle-free company registration services.

</p>
<br />
            <h4>Read More</h4>
        </div>
        <div className='ser_colb'>
            <h3>Contract Reviews</h3>
            <p>Protect your business with our thorough contract review and negotiation services.</p>

<br />
            <h4>Read More</h4>
        </div>
        <div style={{visibility:"hidden"}} className='ser_colb'>
            <h3 data-aos="zoom-in">Financial
Consultancy</h3>

<br />
            <h4>Read More</h4>
        </div>
    </div>
    <br /><br />
    
 </section>
<br /><br /><br /><br />

    <br /><br />
    <section className='abtmin'>
     <div className='lst_row'>
      <div data-aos="fade-right" className='lst2_col'>
        <button>Testominal</button>
        <br /><br />
        <h3>Discover the experiences of our community members and see how Xceed Office has positively impacted their work and personal growth.
</h3>
      </div>
      <div data-aos="fade-left" className='lst2_col'>
        <button id='btn2'><a href="/contact">Let’s Get Started</a></button>
      </div>
     </div>
<br /><br />
   <Slide/>
    </section>
    <br /><br />
    <section data-aos="zoom-in" className='mainlast2'>
       <h3>Have Questions? Let’s Talk</h3>
       <p>Request a consultation and discover how Xceed Office can cater to your workspace needs.</p>
 <br />
 <motion.button  whileHover={{ scale: 1.2 }}
  onHoverStart={e => {}}
  onHoverEnd={e => {}}
> <a href="/contact" data-aos="fade-right">&nbsp;&nbsp;Book a Meeting</a></motion.button>
    </section>
    </div>
  )
}

export default Service