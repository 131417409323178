import React, { useEffect } from 'react'
import xm1 from '../assets/images/david.jpeg'
import sara from '../assets/images/sara.jpeg'
import patal from '../assets/images/patel.jpeg'

import '../components/Styles/slide.css'

const Slide = () => {
  useEffect(()=>{
   
  })

  return (
    <div style={{width:"80%",margin:"auto"}}>
        <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
  <ol class="carousel-indicators">
    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
  </ol>
  <div className="carousel-inner">
    <div className="carousel-item active">
   <div className='slidee'>
    <div className='slrow'> 
    <div className='slrow_cola'>
    < img src={sara}/>
   
          </div>
          <div className='slrow_colb'>
         <h2 style={{lineHeight:"40px"}}>Sarah Thompson</h2>
          <p>"Joining Xceed Office was a game-changer for my freelance career. The vibrant community and the variety of workspaces have not only boosted my productivity but also expanded my professional network. I've never felt more inspired and motivated to achieve my goals!"</p>
   
          </div>
    </div>
    </div>
     </div>
    <div className="carousel-item">
    <div className='slidee'>
    <div className='slrow'> 
    <div className='slrow_cola'>
   < img src={xm1}/>
         
          </div>
          <div className='slrow_colb'>
          <h2 style={{lineHeight:"40px"}}>David Johnson</h2>
          <p>"The amenities and the supportive environment at Xceed Office are unparalleled. The team goes above and beyond to ensure that we have everything we need to succeed. I particularly love the weekly workshops that add so much value to my business."</p>
        
          </div>
    </div>
    </div>
      </div>
    <div className="carousel-item">
    <div className='slidee'>
    <div className='slrow'> 
    <div className='slrow_cola'>
          <img src={patal}/>
          </div>
          <div className='slrow_colb'>
          <h2 style={{lineHeight:"40px"}}>Abigail Spencer</h2>
<p>"Xceed Office is more than just a workspace; it’s a community that cares. The events and workshops provide a fantastic opportunity to learn and connect with like-minded individuals. I am grateful to be a part of this incredible community."</p>

          </div>
    </div>
    </div>
     </div>
     
  </div>
  <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="sr-only">Previous</span>
  </a>
  <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="sr-only">Next</span>
  </a>
</div>
     
    </div>
  )
}

export default Slide;