import React, { useEffect } from 'react'
import '../components/Styles/home.css'
import '../components/Styles/event.css'
import xm1 from '../assets/images/space.png'
import card1 from '../assets/images/card1.png'
import card2 from '../assets/images/card2.png'
import card3 from '../assets/images/card3.png'
import card4 from '../assets/images/card4.png'
import card1a from '../assets/images/card1a.png'
import card2a from '../assets/images/card1b.png'
import card3a from '../assets/images/card1c.png'
import card4a from '../assets/images/card1d.png'
import card5a from '../assets/images/card1e.png'
import AOS from 'aos';
import 'aos/dist/aos.css';


const Events = () => {
  useEffect(()=>{
    AOS.init();
   
  })

  return (
    <div>
        
        <section className='main_sec' >
        <div className='x_row1'>
          <div className='x_col'>
            <h1>Events &   <br />
            Workshops</h1>
<p>Dive into a world of inspiration and connection at Xceed Office, where our events and workshops are designed to ignite creativity, foster networking, and enhance your professional growth. From art exhibitions and musical performances to industry-specific workshops and festivals, we curate a diverse calendar of events to enrich your experience within our community.
</p>

          </div>
          <div className='x_col'>
            <img src={xm1} alt="" />
          </div>
        </div>
        </section>
<br /><br /><br /><br />
<section className='event_mid1'>
<div className='header__event'>
    <button id='bta2'>Recently added</button> <button id='bta1'>All Events</button> <a href="">Art</a> <a href="">Workshops</a><a href="">Festival</a><a href="">Sports</a>
</div>
<br /><br /><br />
<div className='eve_row1'>
 <div><img src={card1} alt="" /></div>
 <div><img src={card2} alt="" /></div>
 <div><img src={card3} alt="" /></div>
 <div><img src={card4} alt="" /></div>

</div>

<div className='eve_row1'>
 <div><img src={card4} alt="" /></div>
 <div><img src={card3} alt="" /></div>
 <div><img src={card2} alt="" /></div>
 <div><img src={card1} alt="" /></div>

</div>
</section>
    <br /><br /><br />

    <section className='eve2'>
    <div className='eve_sub'>
        <br />
      <h2>Explore Categories</h2>
      <div className='eve_row2'> 
      <div>
        <img src={card1a} alt="" />
        <h3>Theater</h3>
      </div>
    
      <div>
        <img src={card2a} alt="" />
        <h3>Family</h3>
      </div>
      <div>
        <img src={card3a} alt="" />
        <h3>Music</h3>
      </div>
      <div>
        <img src={card4a} alt="" />
        <h3>Festival</h3>
      </div>
      <div>
        <img src={card5a} alt="" />
        <h3>Sports</h3>
      </div>
      </div>
    </div>
</section>
<br /><br /><br /><br />

    <section className='abtmin'>
     <div className='lst_row'>
      <div className='lst2_col'>
        <button>Testominal</button>
        <br /><br />
        <h2>Lorem Ipsum is simply dummy text</h2>
      </div>
      <div className='lst2_col'>
        <button id='btn2'> <a href="/contact">Let’s Get Started</a> </button>
      </div>
     </div>
<br /><br />
     <div className='rowwlst'>
         <div className='colll'>Amet minim mollit non deserunt ullamco est
sit aliqua dolor do amet sint. Velit met.Amet minim mollit Velit met.</div>
         <div className='colll'>Amet minim mollit non deserunt ullamco est
sit aliqua dolor do amet sint. Velit met.Amet minim mollit Velit met.</div>
     </div>
<br /><br />
     <div id='col1a'>
     Amet minim mollit non deserunt ullamco est
sit aliqua dolor do amet sint. Velit met.Amet minim mollit Velit met.
     </div>
    </section>
   
    </div>
  )
}

export default Events