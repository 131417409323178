import React, { useEffect } from 'react'
import {motion} from "framer-motion"
import '../components/Styles/home.css'
import '../components/Styles/About.css'
import xm1 from '../assets/images/space.png'
import mem1 from '../assets/images/tmember.png'


import team2 from '../assets/images/team2.png'
import team3 from '../assets/images/team3.png'
import team4 from '../assets/images/team4.png'


import team5 from '../assets/images/team5.png'
import team6 from '../assets/images/team6.png'
import team7 from '../assets/images/team7.png'
import team8 from '../assets/images/team8.png'
import team9 from '../assets/images/team9.png'
import team10 from '../assets/images/team10.png'
import team11 from '../assets/images/team11.png'
import xmidminn from '../assets/images/xmid3-min.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Slide from './Slide'


const Space = () => {
  useEffect(()=>{
    AOS.init();
   
  })

  return (
    <div>
        
        <section className='main_sec' >
        <div className='x_row1'>
          <div className='x_col'>
            <h1 data-aos="fade-right">Spaces &  <br />
            Amenities</h1>
<p data-aos="fade-right">Discover the exceptional range of spaces and amenities designed to cater to your every need at Xceed Office. Our environment is meticulously crafted to ensure comfort, convenience, and productivity. Whether you are looking for a quiet corner to focus on your work, a collaborative space for team discussions, or just a place to relax and recharge, we have it all.
 </p>

          </div>
          <div className='x_col'>
            <img src={xm1} alt="" />
          </div>
        </div>
        </section>
<br /><br /><br /><br />

<section className='main_sec3'>
      
      <div className='row_mlst'>
       <div data-aos="fade-right" className='col_mlst'>
         <h3>Why Choose Us?</h3>
         <h2>Why Xceed Office is the Right Choice for You

</h2>
       </div>
   </div>

      <div className='row_mlst2' data-aos="zoom-in">
       <div className='col_mlst2' >
         <span  style={{display:"flex",alignItems:"center"}}><i class='ri-wifi-fill' style={{color:"#F6BB5B"}}>&nbsp;&nbsp;&nbsp;</i><h3 id='xmlst'>Wifi</h3> </span>
       <p>Experience lightning-fast internet connectivity for smooth work, video calls, and online collaborations</p>
       </div>
       <div className='col_mlst2'>
         <span  style={{display:"flex",alignItems:"center"}}><i class='ri-cup-fill' style={{color:"#F6BB5B"}}>&nbsp;&nbsp;&nbsp;</i><h3 id='xmlst'>Pantry</h3> </span>
       <p>Stocked with snacks and refreshments, our pantry is here to give you that needed energy boost any time of the day.</p>
       </div>
       <div className='col_mlst2'>
         <span  style={{display:"flex",alignItems:"center"}}><i class='ri-printer-fill' style={{color:"#F6BB5B"}}>&nbsp;&nbsp;&nbsp;</i><h3 id='xmlst'>Printer</h3> </span>
       <p>Enjoy hassle-free printing with our state-of-the-art printers, ready for all your document needs.</p>
       </div>
       
      </div>

      <div className='row_mlst2' data-aos="zoom-in">
      <div className='col_mlst2'>
         <span  style={{display:"flex",alignItems:"center"}}><i class='ri-wifi-fill' style={{color:"#F6BB5B"}}>&nbsp;&nbsp;&nbsp;</i><h3 id='xmlst'>Lounge</h3> </span>
       <p>Unwind in our plush lounge, a space for relaxation and informal meetings that can spark creativity.</p>
       </div>

       
       <div className='col_mlst2'>
         <span  style={{display:"flex",alignItems:"center"}}><i class='ri-questionnaire-fill' style={{color:"#F6BB5B"}}>&nbsp;&nbsp;&nbsp;</i><h3 id='xmlst'>Helpdesk</h3> </span>
       <p>Our friendly helpdesk staff are always ready to assist, ensuring your queries and issues are resolved promptly.</p>
       </div>
       <div className='col_mlst2'>
         <span  style={{display:"flex",alignItems:"center"}}><i class='ri-wifi-fill' style={{color:"#F6BB5B"}}>&nbsp;&nbsp;&nbsp;</i><h3 id='xmlst'>Prayer Rooms</h3> </span>
       <p>Find tranquility in our dedicated prayer rooms, which offer a private space for reflection and spiritual practice.</p>
       </div>

      </div>
      <div className='row_mlst2' data-aos="zoom-in">
      <div className='col_mlst2'>
         <span  style={{display:"flex",alignItems:"center"}}><i class='ri-wifi-fill' style={{color:"#F6BB5B"}}>&nbsp;&nbsp;&nbsp;</i><h3 id='xmlst'>Competitive Prices</h3> </span>
       <p>Access our premium amenities at competitive prices, ensuring exceptional value without compromising quality.</p>
       </div>
       <div className='col_mlst2'>
         <span  style={{display:"flex",alignItems:"center"}}><i class='ri-cup-fill' style={{color:"#F6BB5B"}}>&nbsp;&nbsp;&nbsp;</i><h3 id='xmlst'>Tea/Coffee</h3> </span>
       <p>Stay refreshed with complimentary tea and coffee, available whenever you need a pick-me-up.</p>
       </div>


       <div className='col_mlst2'>
         <span  style={{display:"flex",alignItems:"center"}}><i class='ri-wifi-fill' style={{color:"#F6BB5B"}}>&nbsp;&nbsp;&nbsp;</i><h3 id='xmlst'>Meeting Rooms</h3> </span>
       <p>Book our well-equipped meeting rooms for a professional setting suited to discussions, presentations, and collaboration.</p>
       </div>
     </div>
   </section>

<br /><br /><br />  
<section className='abtmin' style={{display:"none"}}>
    <div className='about_row'>
        <div className='abcol1'>
        <h2>Gallery</h2>    
         </div>
        <div className='abcol1b'> 
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever.</p>
        </div>
    </div>
</section>
<br /><br /><br /><br />

       <section className='abtmin'>
        <div className='about_row' data-aos="zoom-in">
         <div className='abcol2'>
            <img src={team9} alt="member" />
         </div>
         <div className='abcol2'>
         <img src={team3} alt="member" />

         </div>
         <div className='abcol2'>
         <img src={team7} alt="member" />

         </div>
        </div>
        <br />
        <div className='about_row' data-aos="zoom-in">
         <div className='abcol2'>
         <img src={team5} alt="member" />

         </div>
         <div className='abcol2'>
         <img src={team6} alt="member" />

         </div>
         <div className='abcol2'>
         <img src={team9} alt="member" />

         </div>
        </div>
 <br />
        <div className='about_row' data-aos="zoom-in">
         <div className='abcol2'>
         <img src={team2} alt="member" />

         </div>
         <div className='abcol2'>
         <img src={team9} alt="member" />

         </div>
         <div className='abcol2'>
         <img src={team10} alt="member" />

         </div>
        </div>
 
       </section>
    <br /><br /><br />
    <section    className='abtmin'>
     <div className='lst_row'>
      <div data-aos="fade-right" className='lst2_col'>
        <button>Testominal</button>
        <br /><br />
        <h3>Discover the experiences of our community members and see how Xceed Office has positively impacted their work and personal growth.
</h3>
      </div>
      <div data-aos="fade-left" className='lst2_col'>
        <button id='btn2'><a href="/membership">Let’s Get Started</a></button>
      </div>
     </div>
<br /><br />
   <Slide/>
    </section>
   
    </div>
  )
}

export default Space