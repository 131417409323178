import React from 'react'
import '../Styles/footer.css'
import logo from '../Header/logo.png'

const Footer = () => {
  return (
    <div>
      <section className='footer__below'>
       <div className='footer__sec'>
         <div className='row_footer'>
           <div className='col_footerb'>
             <h2>Xceed Office</h2>
             <br />
             <p>Xceed Office, established in 2003 by Principal Architect Jordan Peters, is a trailblazer in sustainable and innovative workspace solutions. We pride ourselves on fostering a vibrant community that blends tradition with innovation, offering unparalleled workspace solutions. Join us at Xceed Office, where every detail is designed to enhance your work experience.</p>
           </div>
           <div className='col_footer'>
            <h2>Company</h2>
            <br /><br />
            <a href="/about">About</a>
            <br /><br />
            <a href="/contact">Contact Us</a>
            <br /><br />
            <a href="/careers">Careers</a>
            <br /><br />
            <a href="/faq">Faq's</a>
           </div>
           <div className='col_footer'>
            <h2>Contact Us</h2>
            <br /><br />
            <i class="ri-phone-fill"></i>

            <a href="tel:346 738 0444" >&nbsp;&nbsp;346 738 0444</a>
            <br /><br />
            <i class="ri-mail-line"></i>
            <a href="mailto:info@xceedoffice.com" >&nbsp;&nbsp;info@xceedoffice.com</a>
            <br /><br />
            <i class="ri-map-pin-2-fill"></i>

            <a href='#'>&nbsp;&nbsp;24285 Katy Freeway, Suite 300
Katy TX 77494</a>
           </div>
           <div className='col_footer'>

           <i class="ri-facebook-fill"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           <i class="ri-instagram-fill"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           <i class="ri-twitter-fill"></i>
           </div>
         </div>
       </div>
      </section>
    </div>
  )
}

export default Footer