import React, { useEffect } from 'react'
import {motion} from "framer-motion"
import '../components/Styles/home.css'
import '../components/Styles/About.css'
import '../components/Styles/contact.css'
import xm1 from '../assets/images/contact.png'
import mem1 from '../assets/images/tmember.png'

import AOS from 'aos';
import 'aos/dist/aos.css';


const Contact = () => {
  useEffect(()=>{
    AOS.init();
   
  })

  return (
    <div>
        
        <section className='main_sec' >
        <div className='x_row1'>
          <div className='x_col'>
            <h1 data-aos="fade-right">Contact <br />
Us</h1>
<p data-aos="fade-right">Welcome to Xceed Office's contact page! We are thrilled to have you here and are ready to assist you with any inquiries, support, or information you might need. Our dedicated team is on standby to provide you with timely and efficient responses, ensuring your experience with us is seamless and positive.
</p>

          </div>
          <div className='x_col'>
            <img src={xm1} alt="" />
          </div>
        </div>
        </section>
        <br /><br />
        <section className='con_center'>
       <h2 data-aos="fade-right">Get in Touch</h2>
       <br />

       <div className='mb'>
       
  <label htmlFor="" data-aos="fade-right">First Name</label>
<br />
<input type="text" />
<br /><br />
<label data-aos="fade-right" htmlFor="">Last Name</label>
<br />
<input type="text" />
<br /><br />
<label htmlFor="" data-aos="fade-right">Email</label>
<br />
<input type="text" />
<br /><br />
<label htmlFor="" data-aos="fade-right">Phone Number</label>
<br />
<input type="text" />

<br /><br />
<label htmlFor="" data-aos="fade-right">Message</label>
<br />
<input type="text" />
<br /><br />
<button data-aos="fade-right">Send Message</button>
<br /><br />
</div>

       <div className='con_row'>
     <div className='con_col'>
     <h2 data-aos="fade-right">Contact Information</h2>
     <p data-aos="fade-right"> Say something to start a live chat!</p>
     <br />
    <div className='con_rowico'>
     <div className='can_colico'>
        <i class="ri-phone-fill"></i>
      </div>
      
      <div className='can_col2ico'>
      
       <a href="tel:346 738 0444" data-aos="fade-right" >&nbsp;&nbsp;346 738 0444</a>
      </div>
    </div>
    <br />
    <div className='con_rowico'>
     <div className='can_colico'>
     <i class="ri-mail-line"></i>   
      </div>
      <div className='can_col2ico'>
    
      <a href="mailto:info@xceedoffice.com" >&nbsp;&nbsp;info@xceedoffice.com</a>
      </div>
    </div>
    <br />
    <div className='con_rowico'>
     <div className='can_colico'>
     <i class="ri-map-pin-2-fill"></i>
      </div>
      
      <div className='can_col2ico'>
     <p data-aos="fade-right"> 178 Firelos Street, STE 1100, San Francisco, USA 2437321
</p>
      </div>
    </div>
    </div>

<div className='con_col2'>
<div className='rowinp'>
<div className='colinp'>
<label htmlFor="" data-aos="fade-right">First Name</label>
<br />
<input type="text" />
</div>
<div className='colinp'>
<label data-aos="fade-right" htmlFor="">Last Name</label>
<br />
<input type="text" />
</div>
</div>
<br />
<div className='rowinp'>
<div className='colinp'>
<label htmlFor="" data-aos="fade-right">Email</label>
<br />
<input type="text" />
</div>
<div className='colinp'>
<label htmlFor="" data-aos="fade-right">Phone Number</label>
<br />
<input type="text" />
</div>
</div>
<br />
<label htmlFor="" data-aos="fade-right">Message</label>
<br />
<input type="text" />
<br /><br />
<button data-aos="fade-right">Send Message</button>

</div>

    </div>

       </section>

<br /><br /><br /><br />


<section data-aos="zoom-in" className='mainlast2'>
       <h3>Have Questions? Let’s Talk</h3>
       <p>Request a consultation and discover how Xceed Office can cater to your workspace needs.</p>
 <br />
 <motion.button  whileHover={{ scale: 1.2 }}
  onHoverStart={e => {}}
  onHoverEnd={e => {}}
><a href="tel:+(281) 717-6060" data-aos="fade-right">&nbsp;&nbsp;Book a Meeting</a></motion.button>
    </section>
 
    </div>
  )
}

export default Contact