import React, { useEffect } from 'react'
import {motion} from "framer-motion"
import '../components/Styles/home.css'
import '../components/Styles/About.css'
import '../components/Styles/faq.css'
import xm1 from '../assets/images/aboutmain.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Slide from './Slide'


const Faq = () => {
  useEffect(()=>{
    AOS.init();
   
  })

  return (
    <div>
        
        <section className='main_sec' >
        <div className='x_row1'>
          <div className='x_col'>
            <h1>FAQ <br />
</h1>
<p>Welcome to Xceed Office, where we strive to provide clarity and support for all your workspace needs. Below, you'll find answers to some of the most common questions we receive, helping to simplify your journey with us.
</p>

          </div>
          <div className='x_col'>
            <img src={xm1} alt="" />
          </div>
        </div>
        </section>
<br /><br /><br /><br />
<section className='mid_faq'>
<details>
  <summary data-aos="fade-right">1. How can I schedule a tour of the Xceed Office workspace?</summary>
  <div>
  Scheduling a tour is easy! Simply click on the "Schedule A Tour" option in the main menu, and you'll be guided through the process. We look forward to showing you around and answering any questions you may have.
</div>
</details>
<br />
<details>
  <summary data-aos="fade-right">2. What amenities are available at Xceed Office?</summary>
  <div>
  At Xceed Office, we offer a wide range of amenities to enhance your work experience. This includes high-speed Wi-Fi, a fully-equipped pantry, printing facilities, comfortable lounge areas, a dedicated helpdesk, serene prayer rooms, competitive pricing, and complimentary tea and coffee. We’ve got everything you need to stay productive and comfortable.


</div>
</details>
<br />
<details>
  <summary data-aos="fade-right">3. Can I access the workspace during weekends?</summary>
  <div>
  Yes, our workspace is accessible during weekends. We understand the importance of flexibility, and we strive to accommodate the varying schedules of our community members.
</div>
</details>
<br />
<details>
  <summary data-aos="fade-right">4. What safety measures are in place at Xceed Office?</summary>
  <div>
  Your safety is our top priority. We have implemented comprehensive safety measures including regular cleaning and sanitization, social distancing practices, and providing hand sanitizers throughout the workspace. Our team is also readily available to assist with any safety concerns you may have.


</div>
</details>
<br />
<details>
  <summary data-aos="fade-right">5. How do I become a member of Xceed Office?

</summary>
  <div>
  Becoming a member is simple. Visit our "Membership & Pricing" page to explore the different packages we offer, and choose the one that best suits your needs. If you have any questions or need assistance, our team is here to help.


</div>
</details>
<br />

<details>
  <summary data-aos="fade-right">6. Are there opportunities to network and connect with other professionals at Xceed Office?



</summary>
  <div>
  Absolutely! Xceed Office is a vibrant community of professionals from various industries. We regularly host events, workshops, and networking sessions to foster connections and collaboration among our members.




</div>
</details>
<br />
<details>
  <summary data-aos="fade-right">7. How can I stay updated on upcoming events and workshops?



</summary>
  <div>
  Stay in the loop by signing up for our newsletter and following us on social media. We regularly update our community on upcoming events, workshops, and other exciting opportunities to connect and learn.



</div>
</details>

</section>

<br /><br /><br />

    <br /><br />
    <section className='abtmin'>
     <div className='lst_row'>
      <div data-aos="fade-right" className='lst2_col'>
        <button>Testominal</button>
        <br /><br />
        <h3>Discover the experiences of our community members and see how Xceed Office has positively impacted their work and personal growth.
</h3>
      </div>
      <div data-aos="fade-left" className='lst2_col'>
        <button id='btn2'><a href="/contact">Let’s Get Started</a></button>
      </div>
     </div>
<br /><br />
   <Slide/>
    </section>
    <br /><br />
    <section data-aos="zoom-in" className='mainlast2'>
       <h3>Have Questions? Let’s Talk</h3>
       <p>Request a consultation and discover how Xceed Office can cater to your workspace needs.</p>
 <br />
 <motion.button  whileHover={{ scale: 1.2 }}
  onHoverStart={e => {}}
  onHoverEnd={e => {}}
> <a href="/contact" data-aos="fade-right">&nbsp;&nbsp;Book a Meeting</a></motion.button>
    </section>
    </div>
  )
}

export default Faq