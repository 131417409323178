import React, { useEffect } from 'react'
import {motion} from "framer-motion"
import '../components/Styles/home.css'

import '../components/Styles/member.css'
import xm1 from '../assets/images/aboutmain.png'
import eli from '../assets/images/eli.png'
import elib from '../assets/images/elib.png'
import check from '../assets/images/check.png'
import icona from '../assets/images/icona.png'
import iconb from '../assets/images/iconb.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Slide from './Slide'


const Member = () => {
  useEffect(()=>{
    AOS.init();
   
  })

  return (
    <div>
        
        <section className='main_sec' >
        <div className='x_row1'>
          <div className='x_col'>
            <h1  data-aos="fade-right">Membership     <br />
            & Pricing</h1>
<p  data-aos="fade-right">
Elevate your work experience with our diverse range of membership options tailored to suit your professional needs. At Xceed Office, we are dedicated to providing a vibrant and conducive workspace that fosters productivity and innovation.
</p>

          </div>
          <div className='x_col'>
            <img src={xm1} alt="" />
          </div>
        </div>
        </section>
<br /><br /><br /><br />


<section  data-aos="zoom-in" className='member_sec'>
<h4>Pricing</h4>
<h2>Tailored Workspace Solutions
</h2>
<p>Choose from our flexible membership plans designed to accommodate the unique demands of your work, ensuring you only pay for what you need.

</p>
<div className='mem_row'>
<div className='mem_cola'>
    
    <div className='mem_row'>
       <div className='mem_colbca'>
        <img src={eli} alt="" />
        <img src={elib} alt="" />
       </div>
       <div className='mem_colbcd'>
        <p id='textbx2'>Hot Desk

</p>
       </div>
    </div>

    <p id='textbx3'>Access to any available desk in a common area. High-speed Wi-Fi. Free coffee and tea. </p>
    <h3 id='textbx4'>$150 <span id='textbx5'>/monthly</span> </h3>
    <h3 id='textbx8'>What’s included</h3>
    <div id='chek'>
 <img src={check}  alt="" />
     <p >Annually (Save up to 10%)</p> 
</div>

<br />
  <button id='bttn'>Get started   </button>
</div>


<div className='mem_colb'>
    
    <div className='mem_row'>
       <div className='mem_colbca'>
        <img style={{width:"80%",}} src={icona} alt="" />
       
       </div>
       <div className='mem_colbcd'>
        <p id='textbx2' >   Desk</p>
       </div>
    </div>

    <p id='textbx3'>Your own reserved desk in a shared space. Lockable storage unit. 24/7 access to the workspace.</p>
    <h3 id='textbx4'>$250<span id='textbx5'>&nbsp;&nbsp;/monthly</span> </h3>
    <h3 id='textbx8'>What’s included</h3>
<div id='chek'>
 <img src={check}  alt="" />
     <p>Annually (Save up to 10%)</p> 
</div>
<br />
  <button id='bttn'>Get started   </button>
</div>
<div className='mem_cola'>
    
    <div className='mem_row'>
       <div className='mem_colbca'>
       <img style={{width:"90%",}} src={iconb} alt="" />
       </div>
       <div className='mem_colbcd'>

        <p id='textbx2'>Virtual Office</p>
       </div>
    </div>

    <p id='textbx3'>Business address and mail handling. Phone answering service. Access to workspace and meeting rooms as needed.</p>
    <h3 id='textbx4'>$50<span id='textbx5'>&nbsp;&nbsp;/monthly</span> </h3>
    <h3 id='textbx8'>What’s included</h3>
<div id='chek'>
 <img src={check}  alt="" />
     <p>Annually (Save up to 10%)</p> 
</div>
<br />
  <button id='bttn'>Get started   </button>
</div>
<div className='mem_colb'>
    
    <div className='mem_row'>
       <div className='mem_colbca'>
        <img src={eli} alt="" />
        <img src={elib} alt="" />
       </div>
       <div className='mem_colbcd'>
        <p id='textbx2'>Private Office</p>
       </div>
    </div>

    <p id='textbx3'>Fully furnished, lockable office space. Access to meeting rooms. Customizable to your team’s needs. <span style={{visibility:"hidden"}}>shah</span></p>
    <h3 id='textbx4'>$500<span id='textbx5'>&nbsp;&nbsp;/monthly</span> </h3>
    <h3 id='textbx8'>What’s included</h3>
<div id='chek'>
 <img src={check}  alt="" />
     <p>Annually (Save up to 10%)</p> 
</div>
<br />
  <button id='bttn'>Get started   </button>
</div>
</div>
</section>

<br /><br /><br /><br />
<section    className='abtmin'>
     <div className='lst_row'>
      <div data-aos="fade-right" className='lst2_col'>
        <button>Testominal</button>
        <br /><br />
        <h3>Discover the experiences of our community members and see how Xceed Office has positively impacted their work and personal growth.
</h3>
      </div>
      <div data-aos="fade-left" className='lst2_col'>
        <button id='btn2'><a href="/contact">Let’s Get Started</a></button>
      </div>
     </div>
<br /><br />
     <Slide/>
    </section>
   
   
    </div>
  )
}

export default Member