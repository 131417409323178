import React, { useEffect } from 'react'
import {motion} from "framer-motion"
import '../components/Styles/home.css'
import xm1 from '../assets/images/xm1.png'
import xmini from '../assets/images/xmini.png'
import xmidmin from '../assets/images/xmid-min.png'
import xmidminn from '../assets/images/xmid3-min.png'
import mainab from '../assets/images/mainab.png'
import mainac from '../assets/images/mainac.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Slide from './Slide'


const Home = () => {
  useEffect(()=>{
    AOS.init();
   
  })

  return (
    <div>
        
        <section className='main_sec' >
        <div className='x_row1'>
          <div data-aos="zoom-in" className='x_col'>
            <h1 >Space <br /> Ready <br />
For Work</h1>
<p>Elevate your work experience in our meticulously designed workspaces, tailored for professionals seeking excellence and comfort. Join us and transform the way you work.
</p>
<motion.button  whileHover={{ scale: 1.2 }}
  onHoverStart={e => {}}
  onHoverEnd={e => {}}><a href="/service">Get Started</a></motion.button>
          </div>
          <div className='x_col'>
            <img src={xm1} alt="" />
          </div>
        </div>
        </section>
<br /><br /><br /><br />
<section className='main_sec2'>
<div className='x_row1' data-aos="zoom-in">

<motion.div  whileHover={{scale: 1.1,}} className='x_col1'>

  <div className='x_r2'>
    <div className='x_colh'>
      <img src={xmini} alt="" />
    </div>
    <div className='x_colh'>
      <h2>Office Space</h2>
    </div>

  </div>
  <p>Tailored spaces designed for maximum productivity.</p>
</motion.div>

  <motion.div whileHover={{scale: 1.1,}} className='x_col1'>
  <div className='x_r2'>
    <div className='x_colh'>
      <img src={mainab} alt="" />
    </div>
    <div className='x_colh'>
      <h2>Dedicated Desk</h2>
    </div>

  </div>
  <p>Your personal nook in a vibrant professional community</p>  </motion.div>


<motion.div  whileHover={{scale: 1.1,}} className='x_col1'>
   <div className='x_r2'>
    <div className='x_colh'>
      <img src={mainac} alt="" />
    </div>
    <div className='x_colh'>
      <h2>Meeting Room</h2>
    </div>

  </div>
  <p>Modern rooms equipped for successful collaborations</p>  </motion.div>
   
    </div>
    <br /><br />
</section>
       
       <section className='main_sec2'>
         <div className='x_row1'>
         <div className='x_col'>
            <h2 data-aos="fade-right">How does it feel to cowork at The Xceed?</h2>
            <p data-aos="fade-right">Surround yourself in a natural environment that resembles to the farmlands of the midwest. Lay your laptop on a century old reclaimed wood table in one of the oldest historic Soho lofts to date and focus on your work via using a solid fast Wi-Fi. Being located at a newly renovated loft in Soho you might catch yourself imagining the history of NYC by viewing the natural brick wall which was laid in the turn of the century. Being surrounded in the lofts original brick walls, left bare, will certainly bring inspiration to your work.
<br /><br />
While you are in thirst for coffee you might encounter an amazing conversation with a fellow tech founder giving you an advise in that fundraising issue you have been facing. Just before leaving for the day you might bump in to an event happening where everyone is gathered to celebrate the successful work day they have just finished. Makes you wonder how in the earth did you tolerate working in those loud and lonely coffee shops, trying to compete with the espresso machines sound during your important Zoom call.
Our fully furnished loft designed with organic rustic Americana style in mind, will certainly will make you fall in love with work again.</p>
         </div>
         <div className='x_col' >
 <img data-aos="fade-left" id='cpicx' src={xmidmin} alt="" />
 </div>
         </div>
         <br /><br />
       </section>
    
    <section className='main_sec3'>
      
       <div className='row_mlst'>
        <div data-aos="fade-right" className='col_mlst'>
          <h3>Why Choose Us?</h3>
          <h2>Why Xceed Office is the Right Choice for You

</h2>
        </div>
    </div>

       <div className='row_mlst2' data-aos="zoom-in">
        <div className='col_mlst2' >
          <span  style={{display:"flex",alignItems:"center"}}><i class='ri-wifi-fill' style={{color:"#F6BB5B"}}>&nbsp;&nbsp;&nbsp;</i><h3 id='xmlst'>Wifi</h3> </span>
        <p>Experience lightning-fast internet connectivity for smooth work, video calls, and online collaborations</p>
        </div>
        <div className='col_mlst2'>
          <span  style={{display:"flex",alignItems:"center"}}><i class='ri-cup-fill' style={{color:"#F6BB5B"}}>&nbsp;&nbsp;&nbsp;</i><h3 id='xmlst'>Pantry</h3> </span>
        <p>Stocked with snacks and refreshments, our pantry is here to give you that needed energy boost any time of the day.</p>
        </div>
        <div className='col_mlst2'>
          <span  style={{display:"flex",alignItems:"center"}}><i class='ri-printer-fill' style={{color:"#F6BB5B"}}>&nbsp;&nbsp;&nbsp;</i><h3 id='xmlst'>Printer</h3> </span>
        <p>Enjoy hassle-free printing with our state-of-the-art printers, ready for all your document needs.</p>
        </div>
        
       </div>

       <div className='row_mlst2' data-aos="zoom-in">
       <div className='col_mlst2'>
          <span  style={{display:"flex",alignItems:"center"}}><i class='ri-wifi-fill' style={{color:"#F6BB5B"}}>&nbsp;&nbsp;&nbsp;</i><h3 id='xmlst'>Lounge</h3> </span>
        <p>Unwind in our plush lounge, a space for relaxation and informal meetings that can spark creativity.</p>
        </div>

        
        <div className='col_mlst2'>
          <span  style={{display:"flex",alignItems:"center"}}><i class='ri-questionnaire-fill' style={{color:"#F6BB5B"}}>&nbsp;&nbsp;&nbsp;</i><h3 id='xmlst'>Helpdesk</h3> </span>
        <p>Our friendly helpdesk staff are always ready to assist, ensuring your queries and issues are resolved promptly.</p>
        </div>
        <div className='col_mlst2'>
          <span  style={{display:"flex",alignItems:"center"}}><i class='ri-wifi-fill' style={{color:"#F6BB5B"}}>&nbsp;&nbsp;&nbsp;</i><h3 id='xmlst'>Prayer Rooms</h3> </span>
        <p>Find tranquility in our dedicated prayer rooms, which offer a private space for reflection and spiritual practice.</p>
        </div>

       </div>
       <div className='row_mlst2' data-aos="zoom-in">
       <div className='col_mlst2'>
          <span  style={{display:"flex",alignItems:"center"}}><i class='ri-wifi-fill' style={{color:"#F6BB5B"}}>&nbsp;&nbsp;&nbsp;</i><h3 id='xmlst'>Competitive Prices</h3> </span>
        <p>Access our premium amenities at competitive prices, ensuring exceptional value without compromising quality.</p>
        </div>
        <div className='col_mlst2'>
          <span  style={{display:"flex",alignItems:"center"}}><i class='ri-cup-fill' style={{color:"#F6BB5B"}}>&nbsp;&nbsp;&nbsp;</i><h3 id='xmlst'>Tea/Coffee</h3> </span>
        <p>Stay refreshed with complimentary tea and coffee, available whenever you need a pick-me-up.</p>
        </div>


        <div className='col_mlst2'>
          <span  style={{display:"flex",alignItems:"center"}}><i class='ri-wifi-fill' style={{color:"#F6BB5B"}}>&nbsp;&nbsp;&nbsp;</i><h3 id='xmlst'>Meeting Rooms</h3> </span>
        <p>Book our well-equipped meeting rooms for a professional setting suited to discussions, presentations, and collaboration.</p>
        </div>
      </div>
    </section>
    <br /><br />
    <section className='lstpic'>
        <img src={xmidminn} alt="" />
        <div>
          <h2>Ready to Take the Next Step?</h2>
          <p>Join the Xceed Office community today and elevate your work experience.</p>
       <br />
        <button><a href="/about">Join Now</a></button>
        </div>
        
    </section>

    <section className='lastmain'>
     <div className='lst_row'>
      <div data-aos="fade-right" className='lst2_col'>
        <button>Testominal</button>
        <br /><br />
        <h3>Discover the experiences of our community members and see how Xceed Office has positively impacted their work and personal growth.
</h3>
      </div>
      <div data-aos="fade-left" className='lst2_col'>
        <button id='btn2'><a href="/contact">Let’s Get Started</a></button>
      </div>
     </div>
<br /><br />
<Slide/>
    </section>
    <br /><br />
    <section data-aos="zoom-in" className='mainlast2'>
       <h3>Have Questions? Let’s Talk</h3>
       <p>Request a consultation and discover how Xceed Office can cater to your workspace needs.</p>
 <br />
 <motion.button  whileHover={{ scale: 1.2 }}
  onHoverStart={e => {}}
  onHoverEnd={e => {}}
>      <a href="/contact" data-aos="fade-right">&nbsp;&nbsp;Book a Meeting</a></motion.button>
    </section>
    </div>
  )
}

export default Home