import React, { useEffect } from 'react'
import {motion} from "framer-motion"
import '../components/Styles/home.css'

import '../components/Styles/career.css'
import xm1 from '../assets/images/aboutmain.png'
import cre1 from '../assets/images/growth.png'
import cre2 from '../assets/images/gpep.png'
import cre3 from '../assets/images/award.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Slide from './Slide'


const Career = () => {
  useEffect(()=>{
    AOS.init();
   
  })

  return (
    <div>
        
        <section className='main_sec' >
        <div className='x_row1'>
          <div className='x_col'>
            <h1 data-aos="fade-right">Careers</h1>
<p data-aos="fade-right">Embark on a rewarding career journey with Xceed Office, where innovation meets opportunity. Join a team that values growth, collaboration, and a positive work environment.</p>

          </div>
          <div className='x_col'>
            <img src={xm1} alt="" />
          </div>
        </div>
        </section>
<br /><br /><br /><br />

<section className='career_sec'>
  <h3 data-aos="zoom-in">How We Hire Our Team</h3>
  <p data-aos="zoom-in">Our hiring process is straightforward and transparent, ensuring we connect with the best talent while providing a great candidate experience.
</p>
  <br />
  <div className='car_row' data-aos="zoom-in">
    <div className='car_col'>
        <h4>Application and Recruiter Screen:</h4>
        <p>Kickstart your journey with us by submitting your application. Our recruitment team is eager to delve into your resume, understand your skills, and explore your potential contributions to our team.
</p>
    </div>
    <div className='car_col'>
        <h4>First-Round Interview:</h4>
        <p> This is your opportunity to shine and share your story. We want to know more about your experiences, your drive, and how you overcome challenges. It’s a conversation to explore mutual fit and understand how you can thrive at Xceed Office.</p>
    </div>
    <div className='car_col'>
        <h4>Task or Challenge:</h4>
        <p> Show us your skills in action! We believe in practical assessments, giving you a chance to demonstrate your expertise and problem-solving abilities. It’s not just about what you know, but how you apply it.
</p>
    </div>
    <div className='car_col'>
        <h4>Final Interview:</h4>
        <p>Meet the decision-makers and future colleagues. This is the time to ask questions, get a feel for our culture, and see how you can contribute to our shared success.</p>
    </div>
  </div>
</section>

<br /><br /><br /><br />
<section className='sec_mid' data-aos="zoom-in">
<h2>Why Work With Us</h2>
<br /><br />
<div className='car_ro'>
<div className='col_r'>
    <img src={cre1} alt="pic" />
   
    <h3>Rapid Growth: </h3>
    
    <p>Be part of a company that’s on the move. We’re growing fast, providing endless opportunities for career advancement.</p>
</div>
<div className='col_r'>
<img src={cre2} alt="pic" />
<h3>Executive Mentors:</h3>
    <p>Learn from the best. Our leaders are here to guide you, share their experiences, and help you reach your full potential.</p>

</div>
<div className='col_r'>
<img src={cre3} alt="pic" />
<h3>Great Perks:</h3>
    <p>We value our team, and we show it. Enjoy a range of benefits and perks designed to support your well-being and work-life balance.</p>
</div>
</div>


</section>
<br /><br /><br />
<section className='cr_sec3'>
    <h2 data-aos="fade-right">Open Positions
</h2>
    <p data-aos="fade-right">Get to know us and join our team. We'd love to have more talented people on board. Check out our open positions!
</p>
<br />
    <div className='cr_sec3_r'>
    
    <div data-aos="fade-right" className='col_sec3_r'>
             <div  className='cr_sec3_r'>
               <div className='col__sub_c1'><h3>YouTube Video Editor</h3>
               <p>Bring stories to life and engage our audience with your video editing magic.
</p>
               </div>
               <div className='col__sub_c2'><i  class="ri-arrow-right-line"></i></div>

             </div>
             
     </div>
    
     <div data-aos="fade-left" className='col_sec3_r'>
     <div className='cr_sec3_r'>
               <div className='col__sub_c1'><h3>YouTube Executive:</h3>
               <p>Drive our YouTube strategy, ensuring we connect with our audience and grow our channel.
</p></div>
               <div className='col__sub_c2'><i class="ri-arrow-right-line"></i></div>

             </div>

     </div>
     
    </div>
    <br />
    <div data-aos="fade-right" className='cr_sec3_r'>
    
    <div className='col_sec3_r'>
             <div className='cr_sec3_r'>
               <div className='col__sub_c1'><h3>Graphic Designer for YouTube & Social Media:</h3><p>
               Create compelling visuals that capture attention and convey our message across platforms.
</p></div>
               <div className='col__sub_c2'><i class="ri-arrow-right-line"></i></div>

             </div>
             
     </div>
    
     <div data-aos="fade-left" className='col_sec3_r'>
     <div className='cr_sec3_r'>
               <div className='col__sub_c1'><h3>Sr. Graphic Designer:</h3>
               <p> Lead our design efforts, mentor junior designers, and push the boundaries of what’s possible.</p></div>
               <div className='col__sub_c2'><i class="ri-arrow-right-line"></i></div>

             </div>

     </div>
     
    </div>
</section>    
<br /><br /><br />
<section    className='abtmin'>
     <div className='lst_row'>
      <div data-aos="fade-right" className='lst2_col'>
        <button>Testominal</button>
        <br /><br />
        <h3>Discover the experiences of our community members and see how Xceed Office has positively impacted their work and personal growth.
</h3>
      </div>
      <div data-aos="fade-left" className='lst2_col'>
        <button id='btn2'><a href="/contact">Let’s Get Started</a></button>
      </div>
     </div>
<br /><br />
<Slide/>
    </section>
   
    </div>
  )
}

export default Career