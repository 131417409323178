import {Routes, Route, Navigate} from 'react-router-dom'

import Home from '../pages/Home';
import About from '../pages/About';
import Contact from '../pages/Contact';
import Space from '../pages/Space';
import Events from '../pages/Events';
import Member from '../pages/Member';
import Career from '../pages/Career';
import Faq from '../pages/Faq';
import Bloq from '../pages/Blog';
import Service from '../pages/Services';



const Routers =()=> {
  return (
    <Routes>
      <Route path='/' element={<Home/>} />
      <Route path='/home' element={<Home/>} />
      <Route path='/about' element={<About/>} />
      <Route path='/contact' element={<Contact/>} />
      <Route path='/space' element={<Space/>} />
      <Route path='/event' element={<Events/>} />
      <Route path='/membership' element={<Member/>} />
      <Route path='/career' element={<Career/>} />
      <Route path='/faq' element={<Faq/>} />
      <Route path='/blog' element={<Bloq/>} />
      <Route path='/service' element={<Service/>} />
</Routes>
  )
};
export default Routers;
























