import React, { useEffect } from 'react'
import {motion} from "framer-motion"
import '../components/Styles/home.css'
import '../components/Styles/About.css'
import xm1 from '../assets/images/aboutmain.png'
import mem1 from '../assets/images/tmember.png'
import team1 from '../assets/images/team1.png'
import team2 from '../assets/images/team2.png'
import team3 from '../assets/images/team3.png'
import team4 from '../assets/images/team4.png'
import team8 from '../assets/images/team8.png'
import xmidminn from '../assets/images/xmid3-min.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Slide from './Slide'


const About = () => {
  useEffect(()=>{
    AOS.init();
   
  })

  return (
    <div>
        
        <section className='main_sec' >
        <div className='x_row1'>
          <div className='x_col'>
            <h1 data-aos="fade-right">About <br />
Us</h1>
<p  data-aos="fade-right">Discover the journey and the people behind Xceed Office, where innovation meets tradition, providing you with unparalleled workspace solutions.
 </p>

          </div>
          <div className='x_col'>
            <img src={xm1} alt="" />
          </div>
        </div>
        </section>
<br /><br /><br /><br />

<section className='abtmin'>
    <div className='about_row'>
        <div className='abcol1'>
        <h2 data-aos="fade-right">Our Story:</h2>    
         </div>
        <div data-aos="fade-left" className='abcol1b'>
          <h2>The Journey of Xceed Office</h2> 
        <p>Established in 2003 by the visionary Principal Architect Jordan Peters, Xceed Office has been at the forefront of sustainable and innovative workspace solutions. Our commitment to environmental responsibility and efficient resource management is evident in our extensive portfolio, which spans residential, civic, and commercial design, and more.</p>
        </div>
    </div>
    
</section>
<br /><br /><br /><br />

       <section className='abtmin'>
        <div className='about_row' data-aos="zoom-in">
         <div className='abcol2'>
            <img src={mem1} alt="member" />
         </div>
         <div className='abcol2'>
         <img src={team2} alt="member" />

         </div>
         <div className='abcol2'>
         <img src={team3} alt="member" />

         </div>
        </div>
        <br />
        <div className='about_row' data-aos="zoom-in">
         <div className='abcol2'>
         <img src={team4} alt="member" />

         </div>
         <div className='abcol2'>
         <img src={team1} alt="member" />

         </div>
         <div className='abcol2'>
         <img src={team8} alt="member" />

         </div>
        </div>
       </section>
    <br /><br /><br />
    <section className='main_sec3'>
      
      <div className='row_mlst'>
       <div data-aos="fade-right" className='col_mlst'>
         <h3>Why Choose Us?</h3>
         <h2>Why Xceed Office is the Right Choice for You

</h2>
       </div>
   
   </div>

      <div className='row_mlst2' data-aos="zoom-in">
      <div className='col_mlst2'>
         <span  style={{display:"flex",alignItems:"center"}}><i class='ri-wifi-fill' style={{color:"#F6BB5B"}}>&nbsp;&nbsp;&nbsp;</i><h3 id='xmlst'>Lounge</h3> </span>
       <p>Unwind in our plush lounge, a space for relaxation and informal meetings that can spark creativity.</p>
       </div>

       
       <div className='col_mlst2'>
         <span  style={{display:"flex",alignItems:"center"}}><i class='ri-wifi-fill' style={{color:"#F6BB5B"}}>&nbsp;&nbsp;&nbsp;</i><h3 id='xmlst'>Helpdesk</h3> </span>
       <p>Our friendly helpdesk staff are always ready to assist, ensuring your queries and issues are resolved promptly.</p>
       </div>
       <div className='col_mlst2'>
         <span  style={{display:"flex",alignItems:"center"}}><i class='ri-wifi-fill' style={{color:"#F6BB5B"}}>&nbsp;&nbsp;&nbsp;</i><h3 id='xmlst'>Prayer Rooms</h3> </span>
       <p>Find tranquility in our dedicated prayer rooms, which offer a private space for reflection and spiritual practice.</p>
       </div>

      </div>
      <div className='row_mlst2' data-aos="zoom-in">
      <div className='col_mlst2'>
         <span  style={{display:"flex",alignItems:"center"}}><i class='ri-wifi-fill' style={{color:"#F6BB5B"}}>&nbsp;&nbsp;&nbsp;</i><h3 id='xmlst'>Competitive Prices</h3> </span>
       <p>Access our premium amenities at competitive prices, ensuring exceptional value without compromising quality.</p>
       </div>
       <div className='col_mlst2'>
         <span  style={{display:"flex",alignItems:"center"}}><i class='ri-wifi-fill' style={{color:"#F6BB5B"}}>&nbsp;&nbsp;&nbsp;</i><h3 id='xmlst'>Tea/Coffee</h3> </span>
       <p>Stay refreshed with complimentary tea and coffee, available whenever you need a pick-me-up.</p>
       </div>


       <div className='col_mlst2'>
         <span  style={{display:"flex",alignItems:"center"}}><i class='ri-wifi-fill' style={{color:"#F6BB5B"}}>&nbsp;&nbsp;&nbsp;</i><h3 id='xmlst'>Meeting Rooms</h3> </span>
       <p>Book our well-equipped meeting rooms for a professional setting suited to discussions, presentations, and collaboration.</p>
       </div>
     </div>
   </section>

    <br /><br />
    <section    className='abtmin'>
     <div className='lst_row'>
      <div data-aos="fade-right" className='lst2_col'>
        <button>Testominal</button>
        <br /><br />
        <h3>Discover the experiences of our community members and see how Xceed Office has positively impacted their work and personal growth.
</h3>
      </div>
      <div data-aos="fade-left" className='lst2_col'>
        <button id='btn2'><a href="/contact">Let’s Get Started</a></button>
      </div>
     </div>
<br /><br />
  <Slide/>
    </section>
    <br /><br />
    <section data-aos="zoom-in" className='mainlast2'>
       <h3>Have Questions? Let’s Talk</h3>
       <p>Request a consultation and discover how Xceed Office can cater to your workspace needs.</p>
 <br />
 <motion.button  whileHover={{ scale: 1.2 }}
  onHoverStart={e => {}}
  onHoverEnd={e => {}}
><a href="/contact" data-aos="fade-right">&nbsp;&nbsp;Book a Meeting</a></motion.button>
    </section>
    </div>
  )
}

export default About