


import React, { useEffect, useRef} from 'react'

import { NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import logo from './logo.png'
import './header.css'



 const Header =()=> {


  useEffect(()=>{

// sidebar open close js code
let navLinks = document.querySelector(".nav-links");
let menuOpenBtn = document.querySelector(".navbar .bx-menu");
let menuCloseBtn = document.querySelector(".nav-links .bx-x");


menuOpenBtn.onclick = function() {
navLinks.style.top = "0";

}

menuCloseBtn.onclick = function() {
  navLinks.style.top = "-115%";
}

document.onclick=function(e){
  if( !navLinks.contains(e.target) && !menuOpenBtn.contains(e.target)){
    navLinks.style.top = "-115%";
    }
 }
 });


  return (
    <div >
  <nav >
    <div class="navbar" >
      <i  class='bx bx-menu'><label for="checkbox_toggle" class="hamburger" style={{color:"white"}}>&#9776;</label></i>
      <div class="logo"><NavLink to="/" id='ltext' style={{color:"white",fontWeight:"800",fontFamily:"Ubuntu"}}>Xceed Office</NavLink></div>
      <div class="nav-links" >
        <div class="sidebar-logo">
          <i class='bx bx-x' style={{marginLeft:"70%",marginTop:"20%"}}></i>
        </div>
        <ul class="links">
        <li style={{display:"none"}}><NavLink  to="/">Home</NavLink></li>

        <li><a href="/about">About Us</a></li>
        <li><a href="/space">Space</a></li>
       
<li><a href="/contact">Contact</a></li>
<li style={{display:"none"}}><a href="/event">Event</a></li>
<li ><a href="/membership">Membership</a></li>
<li><a href="/career">Career</a></li>
<li><a href="/faq">Faq</a></li>
<li><a href="/service">Services</a></li>
<li style={{display:"none"}}><button id='bt' ><a href="#" style={{color:"black"}}>Schedule A Tour</a></button></li>

  
        </ul>
      </div>
          </div>
  </nav>

    </div>
  )
}
export default Header;

