import React, { useEffect } from 'react'
import {motion} from "framer-motion"
import '../components/Styles/home.css'
import '../components/Styles/Blog.css'
import '../components/Styles/About.css'
import xm1 from '../assets/images/aboutmain.png'
import blog1 from '../assets/images/blog1.png'
import blog2 from '../assets/images/blog2.png'
import blog3 from '../assets/images/blog3.png'
import compa from '../assets/images/compa.png'
import compb from '../assets/images/compb.png'
import compc from '../assets/images/compc.png'
import compd from '../assets/images/compd.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Slide from './Slide'


const Bloq = () => {
  useEffect(()=>{
    AOS.init();
   
  })

  return (
    <div>
        
        <section className='main_sec' >
        <div className='x_row1'>
          <div className='x_col'>
            <h1>Community 
</h1>
<p>Lorem ipsum dolor sit amet consectetur adipiscing elit, lacinia vestibulum habitant sem felis ullamcorper turpis habitasse, integer duis accumsan ligula laoreet facilisi. </p>

          </div>
          <div className='x_col'>
            <img src={xm1} alt="" />
          </div>
        </div>
        </section>
<br /><br /><br /><br />
<section className='blog_mid'>
<h3>Blogs</h3>
<br />
<div className='blog_row'>
<div className='blog_col'>
<img src={blog1} alt="img" />
<h4>A UX Case Study Creating a Studious Environment for Students: </h4>
<p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.</p>
</div>
<div className='blog_col'>
<img src={blog2} alt="img" />
<h4>A UX Case Study Creating a Studious Environment for Students: </h4>
<p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.</p>
</div>
<div className='blog_col'>
<img src={blog3} alt="img" />
<h4>A UX Case Study Creating a Studious Environment for Students: </h4>
<p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.</p>
</div>

</div>
<br />
<div className='blog_row'>
<div className='blog_col'>
<img src={blog1} alt="img" />
<h4>A UX Case Study Creating a Studious Environment for Students: </h4>
<p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.</p>
</div>
<div className='blog_col'>
<img src={blog2} alt="img" />
<h4>A UX Case Study Creating a Studious Environment for Students: </h4>
<p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.</p>
</div>
<div className='blog_col'>
<img src={blog3} alt="img" />
<h4>A UX Case Study Creating a Studious Environment for Students: </h4>
<p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.</p>
</div>

</div>
</section>

<br /><br /><br /><br />
<section className='blog_mid_2'>
<h2>Collaborations and Partnership</h2>
<br /><br />
<div className='blog2_row'>
<div className='blog2_col'>
    <img src={compa} alt="com" />
</div>
<div className='blog2_col'>
    <img src={compd} alt="com" />
</div>
<div className='blog2_col'>
    <img src={compc} alt="com" />
</div>
<div className='blog2_col'>
    <img src={compd} alt="com" />
</div>
<div className='blog2_col'>
    <img src={compa} alt="com" />
</div>
</div>
</section>
    <br /><br /><br />
    <section className='abtmin'>
     <div className='lst_row'>
      <div data-aos="fade-right" className='lst2_col'>
        <button>Testominal</button>
        <br /><br />
        <h3>Discover the experiences of our community members and see how Xceed Office has positively impacted their work and personal growth.
</h3>
      </div>
      <div data-aos="fade-left" className='lst2_col'>
        <button id='btn2'>Let’s Get Started</button>
      </div>
     </div>
<br /><br />
  <Slide/>
    </section>
    <br /><br />
    <section data-aos="zoom-in" className='mainlast2'>
       <h3>Have Questions? Let’s Talk</h3>
       <p>Request a consultation and discover how Xceed Office can cater to your workspace needs.</p>
 <br />
 <motion.button  whileHover={{ scale: 1.2 }}
  onHoverStart={e => {}}
  onHoverEnd={e => {}}
>Book a Meeting</motion.button>
    </section>
    </div>
  )
}

export default Bloq